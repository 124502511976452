<template>
  <div>
    <p class="fz28rem mb2rem ">附件粘接操作教材</p>
    <p class="fz22rem mb1rem mt6rem ">附件作用</p>
    <div class="fz16rem help_center_line_height">
      <p>附件是由医生在临床上选择与患者牙齿颜色相接近的光固化树脂，通过附件粘结模板制作，并粘结在牙齿上。有利于矫治器固位、辅助牙齿移动、保证矫治效果。</p>
    </div>
    <p class="fz22rem mb1rem mt6rem ">操作步骤</p>
    <div class="fz16rem help_center_line_height">
      <p class="mb1rem">(1) 试戴附件模版，检查是否贴合。</p>
      <!--<img class="mb1rem" style="display: block;" src="../../../common/imgs/operation_teaching/img1.png" alt="">-->
      <p class="mb1rem">(2) 清洁抛光牙面。</p>
      <!--<img class="mb1rem" style="display: block;" src="../../../common/imgs/operation_teaching/img2.png" alt="">-->
      <p class="mb1rem">(3) 酒精棉球擦拭牙面并吹干牙面。</p>
      <!--<img class="mb1rem" style="display: block;" src="../../../common/imgs/operation_teaching/img3.png" alt="">-->
      <p class="mb1rem">(4) 根据附件的位置，在牙面上附件对应的区域涂布酸蚀剂20-30s（37%磷酸酸蚀剂），冲洗干净并吹干牙面。</p>
      <!--<img class="mb1rem" style="display: block;" src="../../../common/imgs/operation_teaching/img4.png" alt="">-->
      <p class="mb1rem">(5) 在酸蚀面上均匀涂布粘接剂，并用气枪轻吹，使粘接剂成为均匀的薄层，光固化10s。</p>
      <!--<img class="mb1rem" style="display: block;" src="../../../common/imgs/operation_teaching/img5.png" alt="">-->
      <p class="mb1rem">(6) 使用固体树脂充填模版。</p>
      <!--<img class="mb1rem" style="display: block;" src="../../../common/imgs/operation_teaching/img6.png" alt="">-->
      <p class="mb1rem">(7) 戴入附件模版，按压咬合面确保模版完全就位；用树脂充填器按压附件边缘，使附件边缘清晰并与牙面紧密贴合。光固化40s。</p>
      <p class="mb1rem">(8) 使用刮治器或钨钢球钻去除附件周围多余粘接剂和树脂菲边。</p>
      <p class="mb1rem">(9) 最后戴入矫治器，检查是否密合。</p>
      <p>Tips：</p>
      <p>1.根据需要，模板可剪断分区域粘结。</p>
      <p>2.如果附件脱落，可用前一个或正在戴用的矫治器作为模板重新粘结。</p>
      <!--<img style="display: block;" src="../../../common/imgs/operation_teaching/img7.png" alt="">-->
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    computed: {

    },

    methods: {

    },

    beforeDestroy() {
    },
    created() {
    }
  }
</script>

<style scoped>

</style>