<template>
  <div class="main_theme_color_333">
    <p class="fz28rem mb2rem ">X光片拍摄标准</p>
    <p class="fz22rem mb1rem mt6rem ">全颌曲面断层片</p>
    <div class="fz16rem help_center_line_height">
      <p>(1) 源文件jpg图片；</p>
      <p>(2) 髁突、上下颌显示完整，牙冠、牙根、牙槽骨清晰；</p>
      <p>(3) 图像对比显示良好；</p>
      <p>(4) 没有可见的饰品；</p>
      <p>(5) 左右侧标记。</p>
      <img style="display: block;width: 50%;" src="../../../common/imgs/x_picture_shoot/img1.png" alt="">
    </div>
    <p class=" fz22rem mb1rem mt6rem ">头颅侧位定位片</p>
    <div class="fz16rem help_center_line_height">
      <p>(1) 源文件jpg图片；</p>
      <p>(2) 自然头位，</p>
      <p>(3) 牙尖交错咬合；</p>
      <p>(4) 图像上方显示前额，下方显示颈部，前方显示鼻尖，后方显示部分枕部；</p>
      <p>(5) 图像对比良好，硬软组织显示清晰；</p>
      <p>(6) 没有可见的饰品。</p>
      <img style="display: block;width: 50%;" src="../../../common/imgs/x_picture_shoot/img2.png" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    computed: {

    },

    methods: {

    },

    beforeDestroy() {
    },
    created() {
    }
  }
</script>

<style scoped>

</style>