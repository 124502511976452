<template>
  <div>
    <p class="fz28rem mb2rem ">邻面去釉操作教材</p>
    <p style="margin-top: 0.51rem;"
      class="fz16rem help_center_line_height mb1rem">邻面去釉(Interproximal Reduction ，简称IPR)又称减径、片切，是无托槽隐形矫治技术中获取间隙、解除拥挤的方法之一。</p>
    <p class="fz22rem mb1rem mt6rem ">邻面去釉的适应症</p>
    <div class="fz16rem help_center_line_height">
      <p>1.非齲易感患者；</p>
      <p>2.牙体组织有足够的宽度；</p>
      <p>3.牙体形态适合邻面去釉。</p>
    </div>
    <p class="fz22rem mb1rem mt6rem ">邻面去釉的禁忌症</p>
    <div class="fz16rem help_center_line_height">
      <p>1.牙弓内拥挤超过8mm ；</p>
      <p>2.口腔卫生差或牙周环境不良者；</p>
      <p>3.畸形过小牙和对冷热刺激敏感者慎用，防止引起或增加牙齿敏感症；</p>
      <p>4.齲易感患者和有大面积充填修复患者；</p>
      <p>5.特殊的牙齿外形:对于那些方形牙(牙齿邻接面为平面、基底大) ，因其外形特点增加了它们的邻接面积，容易造成食物嵌塞、牙槽嵴吸收。</p>
    </div>
    <p class=" fz22rem mb1rem mt6rem ">邻面去釉的原则</p>
    <div class="fz16rem help_center_line_height">
      <p>1.邻面去釉必须选择正确的适应症；</p>
      <p>2.邻面去釉量必须在安全范围内；</p>
      <p>3.邻面去釉的临床操作要规范；</p>
      <p>4.矫治过程中可能需要追加邻面去釉，应按照矫治过程简要说明上的提示结合临床实际，把握好邻面去釉的时机和去釉量。</p>
    </div>
    <p class=" fz22rem mb1rem mt6rem ">有关邻面去釉量</p>
    <div class="fz16rem help_center_line_height">
      <p>目前最为大家所接受的最大邻面去釉量为不超过原牙釉质厚度的50% ，不至于影响牙体和牙周健康。根据此原则多数情况下，尖牙远中面及前磨牙和磨牙单侧釉质面最多可以被磨除0.5mm，即从第二磨牙近中到尖牙远中的每个邻接面可以获得大约1mm的间隙。切牙单侧釉质面最多可以被磨除0.25mm ，即切牙间每个邻接面可以获得大约0.5mm的间隙。</p>
    </div>
    <p class=" fz22rem mb1rem mt6rem ">邻面去釉后的牙齿是否容易患龋?</p>
    <div class="fz16rem help_center_line_height">
      <p>研究表明，邻面去釉后的釉质比原来的釉质抗龋能力更强。磨除表层完全矿化的釉质，会增强去釉后表面釉质再矿化（抗酸)的潜力。</p>
    </div>
    <p class=" fz22rem mb1rem mt6rem ">邻面去釉的临床操作</p>
    <p class="mb1rem mt2rem fz18rem ">高速车针</p>
    <div class="fz16rem help_center_line_height">
      <p>1.下图第一张为金刚砂车针，用于高速手机上，前面2个红标的是去釉车针，中间红标的是轴角修整车针，后面2个黄标的是抛光车针；</p>
      <p>2.一般用于去釉量大于0.4mm时；</p>
      <p>3.先用去釉车针，车针垂直于牙长轴，自龈方向𬌗方提拉进入牙齿邻接区，唇（颊）舌（腭）向分别操作，分开邻接点，防止龈下台阶的形成，动作要轻柔；</p>
      <p>4.再用轴角修整车针修整轴角，恢复外展隙；</p>
      <p>5.最后用抛光车针或抛光砂条抛光去釉面。</p>
      <div class="flex-x-between mt2rem">
        <img style="display: block;width: 45%;height: 5%;" src="../../../common/imgs/led_to_glaze/img1.png" alt="">
        <img style="display: block;width: 45%;height: 5%;" src="../../../common/imgs/led_to_glaze/img2.png" alt="">
      </div>
      <div style="margin-top: 0.6rem" class="flex-x-between">
        <img style="display: block;width: 45%;height: 5%;" src="../../../common/imgs/led_to_glaze/img3.png" alt="">
        <img style="display: block;width: 45%;height: 5%;" src="../../../common/imgs/led_to_glaze/img4.png" alt="">
      </div>
    </div>
    <p class=" fz22rem mb1rem mt6rem ">金钢砂条(砂片)</p>
    <div class="fz16rem help_center_line_height">
      <p>1.金钢砂条(砂片)分手用和机用两种；</p>
      <p>2.需要的去釉量较小（小于0.2mm) ，特别是前牙和后期微调整时使用。可以松解邻接点；</p>
      <p>3.其它方法去釉后用砂条修整，使牙齿形态更自然；</p>
      <p>4.从最薄的砂条开始，使用金钢砂条时用砂条包绕牙齿邻面，轻轻拉动。逐步用到最厚的砂条。要加倍去釉量时，应先用单面砂条松解邻接点后，将砂条对折，砂面向外；</p>
      <p>5.机用全钢砂片是安装在专用慢速手机上，通过砂片的唇(颊)舌(腭)向快速微小移动完成牙齿切割。去釉效率高，去釉量准确，去釉后牙体外形好。</p>
      <p>Tips :注意尽量避免伤及龈乳头、保护嘴唇(可在唇与牙齿之间放置棉球)。</p>
      <div style="margin-top: 0.6rem" class="flex-x-between">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img5.png" alt="">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img6.png" alt="">
      </div>
      <div style="margin-top: 0.6rem" class="flex-x-between">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img7.png" alt="">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img8.png" alt="">
      </div>
      <div style="margin-top: 0.6rem" class="flex-x-between">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img9.png" alt="">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img10.png" alt="">
      </div>
      <div style="margin-top: 0.6rem" class="flex-x-between">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img11.png" alt="">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img12.png" alt="">
      </div>
      <div style="margin-top: 0.6rem" class="flex-x-between">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img13.png" alt="">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img14.png" alt="">
      </div>
      <div style="margin-top: 0.6rem" class="flex-x-between">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img15.png" alt="">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img16.png" alt="">
      </div>
      <p class="mt6rem fz16 ">间隙测量</p>
      <p class="fz18rem mt2rem">无论何种去釉方式，去釉完成后，将间隙测量尺垂直于𬌗平面插入去釉邻间，测量并记录去釉量。</p>
      <div style="margin-top: 0.6rem" class="flex-x-between">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img17.png" alt="">
        <img style="display: block;height: 5%;width: 45%;" src="../../../common/imgs/led_to_glaze/img18.png" alt="">
      </div>
      <p class="mt6rem fz16 ">涂氟</p>
      <p class="fz18rem mt2rem">确认去釉量合适后，吹干去釉邻接处，涂布氟保护剂。</p>
      <img class="mt2rem" style="display: block;max-width: 100%" src="../../../common/imgs/led_to_glaze/img19.png" alt="">
    </div>
    <p class=" fz22rem mb1rem mt6rem ">邻面去釉的注意事项</p>
    <div class="fz16rem help_center_line_height">
      <p>1.用先进、适合的器械来实施邻面去釉(公司有偿提供邻面去釉工具包，具体事宜请向公司或当地营销人员咨询)；</p>
      <p>2.小心保护软组织；</p>
      <p>3.不要在牙齿扭转纠正之前进行邻面去釉，只有充分排齐后才能在正确的接触位置进行邻面去釉。若必须在扭转牙部位去袖，则需分牙后再进行；</p>
      <p>4.注意前牙邻面去釉后可能会因外形的改变而影响美观，实施前需与患者充分沟通。</p>
    </div>
    <p class=" fz22rem mb1rem mt6rem ">邻面去釉的技巧</p>
    <div class="fz16rem help_center_line_height">
      <p>1.矫治过程中实施邻面去釉时，一定要把握好去釉的量、部位和时机；</p>
      <p>2.为了尽可能使去釉量精确，首先应用牙线确定是否有邻接点存在。若没有邻接，应用间隙测量尺测量出已有的间隙。邻面去釉后的间隙应减去已有间隙，才是实际增加的邻面去釉量；</p>
      <p>3.用专用的牙间隙测量尺测量间隙。切忌用力把测量尺压入间隙，应轻力感触去釉量的大小；</p>
      <p>4.在邻面去釉记录表上记录去釉量和去釉时机； </p>
      <p>5.每次复诊时用牙线检查邻接点，确认牙齿是否实现了矫治器设计的移动量。</p>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    computed: {

    },

    methods: {

    },

    beforeDestroy() {
    },
    created() {
    }
  }
</script>

<style scoped>

</style>