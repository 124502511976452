<template>
  <div>
    <p class="fz28rem ">照片拍摄标准</p>
    <p class="mt6rem fz22rem mb3rem">面像、口内像的拍摄要求</p>
    <p class="fz22rem mb2rem ">口腔摄影器材选择及拍摄数据设置</p>
    <div class="fz16rem help_center_line_height">
      <p>1、最佳为单反相机配合微距镜头(如NIKON D90、105mm微距镜头； CANON 600D、100mm微距镜头)及环形闪光灯；</p>
      <p>2、以CANON 650D为例</p>
      <p>· 面像拍摄相机设置:人像档、自动白平衡、ISO400、 1/60秒、 F4.0、 闪光补偿未加、曝光补偿未加、打开环形闪光灯。</p>
      <p>· 口内像拍摄相机设置: M档、闪光灯白平衡、ISO200、 1/125秒、 F32、闪光补偿加2档、曝光补偿未加、打开环形闪光灯。</p>
    </div>
    <p class="fz22rem mb2rem mt6rem ">基本要求</p>
    <div class="fz16rem help_center_line_height">
      <p>1.面像背景稳定、患者稍离开背景、无阴影；</p>
      <p>2.照口内像时视野中应无唾液、口红、菌斑、结石、血液等；</p>
      <p>3.口内像应只包括牙齿和软组织；</p>
      <p>4.使用反光板拍摄的照片在提交时应反转至正确方向，以免造成混乱。</p>
    </div>
    <p class="mt6rem mb2rem fz22rem ">正面像</p>
    <div class="fz16rem flex_right help_center_line_height">
      <div>
        <p>1.患者站立或坐立，两眼平视，颏部与地面平行；</p>
        <p>2.牙齿自然咬合在牙尖交错位，唇自然放松；</p>
        <p>3.鼻子在画面中央；</p>
        <p>4.相机正对患者，与牙𬌗平面平行；</p>
        <p>5.照片应包括肩上部到头顶。</p>
      </div>
      <img style="display: block;height: 2rem" src="../../../common/imgs/picture_shoot/img1.png" alt="">
    </div>
    <p class="mt6rem mb2rem fz22rem ">正面微笑像</p>
    <div class="fz16rem flex_right help_center_line_height">
      <div>
        <p>1.患者站立或坐立，两眼平视，颏部与地面平行；</p>
        <p>2.指导患者在牙尖交错咬合状态下自然充分微笑，便于观察牙列中线；</p>
        <p>3.鼻子在画面中央，颏部接近下边缘，颈部在边缘</p>
        <p>4.相机正对患者，与牙𬌗平面平行；</p>
        <p>5.照片应包括肩上部到头顶。</p>
      </div>
      <img style="display: block;height: 2rem" src="../../../common/imgs/picture_shoot/img2.png" alt="">
    </div>
    <p class="mt6rem mb2rem fz22rem ">侧面面像</p>
    <div class="fz16rem flex_right help_center_line_height">
      <div>
        <p>1.指导患者整个身体转向左侧，两眼平视，颏部与地面平行；</p>
        <p>2.牙齿自然咬合在牙尖交错位，唇自然放松；</p>
        <p>3.颏部接近下边缘，颈部在边缘；</p>
        <p>4.相机正对患者；</p>
        <p>5.照片应包括肩上部到头顶，照右侧面，能看到一侧的眼睛及对侧眼睛的睫毛。</p>
      </div>
      <img style="display: block;height: 2rem" src="../../../common/imgs/picture_shoot/img3.png" alt="">
    </div>
    <p class="mt6rem mb2rem fz22rem ">口内正面像</p>
    <div class="fz16rem flex_right help_center_line_height">
      <div>
        <p>1.患者躺在一个舒适的高度；</p>
        <p>2.清理唾液；</p>
        <p>3.用大头开口器向侧前方(不是向后)拉开口颊；</p>
        <p>4.𬌗平面与地面平行，并位于画面垂直向中央；</p>
        <p>5.牙尖交错咬合，舌后置；</p>
        <p>6.在患者正前方镜头与中线垂直，以一侧尖牙对焦后，镜头移至前牙中线拍摄，要有足够景深，保证前后牙均清晰；</p>
        <p>7.尽可能也拍到磨牙颊侧面。</p>
      </div>
      <img style="display: block;height: 2rem" src="../../../common/imgs/picture_shoot/img4.png" alt="">
    </div>
    <p class="mt6rem mb2rem fz22rem ">上颌𬌗面像（上牙列像）</p>
    <div class="fz16rem flex_right help_center_line_height">
      <div>
        <p>1.用末端小的颊部开口器(侧方开口器) ；</p>
        <p>2.患者大张口，颏部向下斜。将上唇向上、侧、前方牵引，也可以让患者自己牵引</p>
        <p>3.把反光板放入口内，并纳入第二磨牙；</p>
        <p>4.舌头位于反光板下，反光板向下直到碰到下切牙。若反光板放置正确，应能看到上颌切牙的腭侧面；</p>
        <p>5.反光板边缘和嘴唇在画面内尽量少。鼻子和反射的牙齿也应在画面之外；</p>
        <p>6.尽量包括更多的牙齿，照片至少包括中切牙唇侧前1-2mm和第二磨牙近中；</p>
        <p>7.镜头与反光板呈约45°角；</p>
        <p>8.以一侧前磨牙区对焦，再将镜头移至腭顶拍摄。不要以腭顶部对焦点。</p>
      </div>
      <img style="display: block;height: 2rem" src="../../../common/imgs/picture_shoot/img5.png" alt="">
    </div>
    <p class="mt6rem mb2rem fz22rem ">下颌𬌗面像（下牙列像）</p>
    <div class="fz16rem flex_right help_center_line_height">
      <div>
        <p>1.用末端小的颊部开口器(侧方开口器) ；</p>
        <p>2.椅位和患者头部尽量斜向后，尽量大张口；</p>
        <p>3.用小头侧方拉钩，将口唇向下、侧、前方牵引；</p>
        <p>4.反光板大头放入口内，舌向上置于反光板后，向上倾斜反光板，直到碰到上切牙。若反光板放置正确，应该能看到下切牙的舌侧面；</p>
        <p>5.反光板边缘和嘴唇在画面内尽量少，鼻子和反射的牙齿也应在画面之外；</p>
        <p>6.尽量包括更多的牙齿，照片至少包括中切牙唇侧前1-2mm和第二二磨牙近中；</p>
        <p>7.镜头与反光板呈约60°角；</p>
        <p>8.以一侧前磨牙区对焦，再将镜头移至口底拍摄。不要以口底对焦点。</p>
      </div>
      <img style="display: block;height: 2rem;margin-left: 0.1rem;" src="../../../common/imgs/picture_shoot/img6.png" alt="">
    </div>
    <p class="mt6rem mb2rem fz22rem ">口内右(左)侧位像</p>
    <div class="fz16rem flex_right help_center_line_height">
      <div>
        <p>1.患者头尽量偏向左(或右) ，用小头侧方拉钩(两个)拉开口颊；</p>
        <p>2.𬌗平面与地面平行，并在画面中央；</p>
        <p>3.牙尖交错咬合，舌后置；</p>
        <p>4.尽量把工作侧颊部向侧方牵引(而不是向后) ，对侧拉钩位于中央；</p>
        <p>5.镜头与牙列侧方垂直；</p>
        <p>6.照片中应看到磨牙和尖牙关系，尖牙位置放置在图像中央，同时显露对侧中切牙。</p>
      </div>
      <img style="display: block;height: 2rem" src="../../../common/imgs/picture_shoot/img7.png" alt="">
    </div>
    <p class="mt6rem mb2rem fz22rem ">口内45°覆𬌗覆盖像</p>
    <div class="fz16rem flex_right help_center_line_height">
      <div>
        <p>1.用大头开口器向侧前方拉开口颊，然后开口器略向下旋转，以免下唇阻挡前牙区覆𬌗覆盖的拍摄；</p>
        <p>2.患者牙尖交错咬合，舌后置；</p>
        <p>3.患者头向后仰45°，使牙𬌗平面与地面呈45°仰角，前牙区覆𬌗覆盖位于画面垂直向中央；</p>
        <p>4.在患者正前方镜头与中线垂直，以一侧尖牙对焦后，镜头移至前牙覆𬌗覆盖区拍摄；</p>
        <p>5.尽量包括更多的牙齿，照片至少包括3-3牙位；</p>
        <p>6.如前牙区反𬌗，则拍摄角度相反-头向下俯角45°拍摄前牙区反覆𬌗反覆盖，开口器略向上旋转，以免上唇阻挡前牙区反覆𬌗反覆盖的拍摄；</p>
      </div>
      <img style="display: block;height: 2rem;margin-left: 0.2rem;" src="../../../common/imgs/picture_shoot/img8.png" alt="">
    </div>
    <p class="mt6rem mb2rem fz22rem ">下颌前伸位口内右(左)侧位像</p>
    <div class="fz16rem flex_right help_center_line_height">
      <div>
        <p>1.患者头尽量偏向左(或右) ，用小头侧方拉钩(两个)拉开口颊；</p>
        <p>2.𬌗平面与地面平行，并在画面中央；</p>
        <p>3.牙齿咬合在医生指导的下颌前伸位，要求尖牙-后牙区牙面完全暴露，舌后置；</p>
        <p>4.尽量把工作侧颊部向侧方牵引(而不是向后) ，对侧拉钩位于中央；</p>
        <p>5.镜头与牙列侧方垂直；</p>
        <p>6.照片中应看到磨牙和尖牙关系，尖牙位置放置在图像中央，同时显露对侧中切牙。</p>
      </div>
      <img style="display: block;height: 2rem;margin-left: 0.2rem;" src="../../../common/imgs/picture_shoot/img9.png" alt="">
    </div>
    <p class="mt6rem mb2rem fz22rem ">下颌前伸位口内正位像</p>
    <div class="fz16rem flex_right help_center_line_height">
      <div>
        <p>1.患者躺在一个舒适的高度；</p>
        <p>2.清理唾液；</p>
        <p>3.用大头开口器向侧前方（不是向后）拉开口颊；</p>
        <p>4.𬌗平面与地面平行，并位于画面垂直向中央；</p>
        <p>5.牙齿咬合在医生指导的下颌前伸位，舌后置；</p>
        <p>6.在患者正前方镜头与中线垂直，以一侧尖牙对焦后，镜头移至前牙中线拍摄，要有足够景深，保证前后牙均清晰；</p>
        <p>7.尽可能也拍到磨牙颊侧面。</p>
      </div>
      <img style="display: block;height: 2rem;margin-left: 0.1rem;" src="../../../common/imgs/picture_shoot/img10.png" alt="">
    </div>
    <p class="mt6rem mb2rem fz22rem ">下颌前伸位前牙覆</p>
    <div class="fz16rem flex_right help_center_line_height">
      <div>
        <p>1.用大头开口器向侧前方拉开口颊，然后开口器略向下旋转，以免下唇阻挡前牙区覆</p>
        <p>2.患者牙齿咬合在医生指导的下颌前伸位，舌后置；</p>
        <p>3.患者头向后仰45°，使𬌗平面与地面呈45°仰角，前牙区覆𬌗覆盖位于画面垂直向中央；</p>
        <p>4.在患者正前方镜头与中线垂直，以一侧尖牙对焦后，镜头移至前牙覆𬌗覆盖区拍摄；</p>
        <p>5.尽量包括更多的牙齿，照片至少包括3-3牙位；</p>
        <p>6.如前牙区反𬌗，则拍摄角度相反-头向下俯角45°拍摄前牙区反覆𬌗反覆盖，开口器略向上旋转，以免上唇阻挡前牙区反覆𬌗反覆盖的拍摄。</p>
      </div>
      <img style="display: block;max-height: 2rem;margin-left: 0.1rem;" src="../../../common/imgs/picture_shoot/img11.png" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    computed: {

    },

    methods: {

    },

    beforeDestroy() {
    },
    created() {
    }
  }
</script>

<style scoped>
  .flex_right{
    display: flex;
    justify-content: space-between;
  }
  .mb3rem{
    margin-bottom: 0.3rem;
  }
</style>