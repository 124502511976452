<template>
  <div>
    <p class="fz28rem mb2rem">模型制取标准</p>
    <p class="fz22rem mb1rem mt6rem">硅橡胶模型标准</p>
    <p class="mb1rem fz18rem mt2rem">取模标准</p>
    <div class="help_center_line_height fz16rem">
      <p>一、要求</p>
      <p>
        1.要求印模清晰、牙列完整、牙冠及牙龈缘无气泡、无缺损、无皱褶。若有第三磨牙，印模应至少包括该牙近中二分之一牙冠；
      </p>
      <p>2.要求使用加聚型硅橡胶；</p>
      <p>3.即使单颌治疗也需取全口印模；</p>
      <p>4.使用公司提供的订制塑料托盘，不得使用金属托盘或含金属材料的托盘。</p>
      <p class="mt2rem">二、硅橡胶取模常见问题及解决方法</p>
      <p>问题一：托盘见底</p>
      <p class="">原因：</p>
      <p>1.取模时加压过大；</p>
      <p>2.托盘不合适。</p>
      <p class="mt2rem">解决方法：</p>
      <p>1.与取藻酸盐印模一样施压；</p>
      <p>
        2.取模前与患者进行良好的沟通很重要。可以先将整个过程给患者讲解一遍，这样在实际操作时患者就会比较放松、配合；
      </p>
      <p>3.选择合适的托盘，不要勉强使用。</p>
      <img
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/img1.png"
        alt=""
      />
      <p class="mt2rem">问题二：终印模有皱褶，导致印模变形</p>
      <p class="">原因：</p>
      <p>1.托盘放入口内前印模材料已经开始结固；</p>
      <p>2.操作超过规定时间；</p>
      <p>3.室温过高缩短了操作时间；</p>
      <p>4.为了填补气泡而在已经结固的印模中注入新的印模材料，重新放入口内；</p>
      <p>5.托盘放置不合适。</p>
      <p class="mt2rem">解决方法：</p>
      <p>1.用定时器提醒；</p>
      <p>2.二人协作完成：一人将印模材料涂于牙上，一人将印模材料注入托盘；</p>
      <p>3.将印模材料保存于阴凉处，远离热源；</p>
      <p>4.取模前将托盘冷却并保持干燥；</p>
      <p>
        5.切勿为了填补气泡而在已经结固的印模中加入新的印模材料，这些后加的印模材料与原印模材料粘结性差，会导致印模产生皱褶及变形；
      </p>
      <p>6.取模时将托盘从后向前就位，确保前牙有足够的空间。</p>
      <img
        class="mt2rem"
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/img2.png"
        alt=""
      />
      <p class="mt2rem">问题三：脱模或初印模与终印模间未完全衔接</p>
      <p class="mt2rem">原因：</p>
      <p>1;托盘与印模之间固位不够；</p>
      <p>2;塑料薄膜仍留存在两层印模之间；</p>
      <p>3.托盘与印模之间、两层印模之间有水或空气。</p>
      <p class="mt2rem">解决方法：</p>
      <p>1.使用公司提供的专用塑料托盘；</p>
      <p>2.取初印时要充分施压，将印模材从托盘孔充分挤出；</p>
      <p>3.用两次法时，确保初印模干燥后，再进行第二次取模；</p>
      <p>4.确保终印印模材料在注射器口时再开始注入，以减少气泡；</p>
      <p>5.用两次法时，取终印模前一定要去除塑料薄膜。</p>
      <img
        class="mt2rem"
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/img3.png"
        alt=""
      />
      <p class="mt2rem">问题四：印模中有气泡或空腔</p>
      <p class="mt2rem">原因：</p>
      <p>1.印模材料中有气泡；</p>
      <p>2.从注射器中挤出的印模材料过细，印模材料之间形成“气泡层”；</p>
      <p>3.口腔后部的牙齿由于视野差，取模时难以充分操作；</p>
      <p>4.口腔不够干燥；</p>
      <p>5.舌或颊部对印模材料的影响。</p>
      <p class="mt2rem">解决方法：</p>
      <p>1.确保注射器头部要注满印模材料；</p>
      <p>2.终模印模材料必须从注射器中推注出，而非拖拉出；</p>
      <p>3.确保印模材料流入前磨牙和磨牙的窝沟内；</p>
      <p>
        4.扩大注射器的开口：若是塑料头，可剪去一部分，若是金属头，想办法将开口扩大一些；
      </p>
      <p>5.两人分工配合、准备好口腔器械、灯光等，有助于取得精确的模型；</p>
      <p>
        6.在上颌颊侧、舌侧放置棉条，并配合充分的吹干、吸唾，有利于控制口腔的湿度。
      </p>
      <img
        class="mt2rem"
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/img4.png"
        alt=""
      />
      <p class="mt2rem">问题五：牙体解剖结构、牙龈组织不清晰或不准确</p>
      <p class="mt2rem">原因：</p>
      <p>1.托盘过于贴近唇侧或舌侧；</p>
      <p>2.托盘就位不足：托盘没有充分就位，会导致牙齿印迹过浅；</p>
      <p>3.牙龈缘清洁不够；</p>
      <p>4.由于初印模层过厚，造成终印印模材料空间不足；</p>
      <p>5.口腔湿度控制不好。</p>
      <p class="mt2rem">解决方法：</p>
      <p>1.用两次法在取终印模前先取初印，以确保终印模不过于靠近唇侧或舌侧；</p>
      <p>
        2.确保终印印模材料流入所有游离龈边缘，包括磨牙远中。对上牙弓来说，这一点至关重要。由于重力的作用，印模材料会脱离龈缘表面；
      </p>
      <p>
        3.取初印模时，因牙齿会将多余的印模材料从颊舌侧挤出，故注入的印模材料不要超过托盘边缘。
      </p>
      <img
        class="mt2rem"
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/img5.png"
        alt=""
      />
      <p class="mt2rem">问题六：托盘选择不当，牙齿印迹超出托盘</p>
      <p class="mt2rem">原因：</p>
      <p>1.取模前没有在口中试合托盘；</p>
      <p>2.托盘末端过短；</p>
      <p>3.托盘过大，前牙区空间过大，但后牙区未达到最后一颗磨牙的远中；</p>
      <p>4.托盘过小，前牙唇面接触托盘或紧贴托盘壁</p>
      <p class="mt2rem">解决方法：</p>
      <p>1.取模前先试合托盘；</p>
      <p>2.托盘必须覆盖至最后一颗牙的远中，并且没有任何阻挡；</p>
      <p>*若前牙与托盘前部边缘之间有大量空隙，则说明托盘过大。</p>
    </div>
    <p class="silica_model fz22rem">如何取模</p>
    <div class="help_center_line_height">
      <p class="fz18rem">两步法</p>
      <div class="fz16rem mt2rem">
        <p>*剪取比托盘稍大一些的塑料隔离膜。</p>
        <p>
          1.取等量重体印模材料，按生产厂家要求的时间定时。用指肚混合搅拌均匀，呈现均一颜色后，搓成条状填入托盘并覆盖至托盘的高度。确保在牙弓周围有足够的材料覆盖。托盘末端材料需要溢出一些，以取到最后磨牙的远中；
        </p>
        <p>2.将隔离膜覆盖在托盘上，使其与印模材料贴附；</p>
        <p>
          3.保持塑料薄膜位置，然后将托盘放置于患者口内，托盘的手柄对准患者鼻子，从后往前均匀施压，使托盘充分就位，应看到重体材料从溢出孔溢出。托盘就位后，前后、上下、左右轻微移动，为轻体预留出一定的间隙；
        </p>
        <p>*节约时间的建议：初印可在研究模型上制取。</p>
        <p>
          4.初印模结固后取出托盘，去除隔离膜。此时的印模还没有牙齿结构的细节，对于比较清晰的牙齿结构要用硅橡胶修整刀修除；
        </p>
        <p>
          5.使用轻体充填器在初印中注入轻体。确保充填器头始终在轻体印模材料内，避免产生气泡。充填轻体时要一次充填足够的量，在最后一颗磨牙的远中多充填一些。轻体充填完成，放入口腔后均匀慢压，使托盘充分就位。按印模材料生产厂家要求的时间定时；
        </p>
      </div>
      <img
        class="mt2rem"
        style="display: block; width: 100%"
        src="../../../common/imgs/help_center/make_model/make_model.png"
        alt=""
      />
    </div>
    <div class="help_center_line_height mt4rem">
      <p class="fz18rem">一步法</p>
      <div class="fz16rem">
        <p>
          1.取等量重体印模材料，按生产厂家要求的时间定时。用指肚混合搅拌均匀，呈现均一颜色后，搓成条状填入托盘并覆盖至托盘的高度。确保在牙弓周围有足够的材料覆盖。托盘末端材料需要溢出一些，以取到最后磨牙的远中；
        </p>
        <p>
          2.用小指侧面在印模材中央压出一条槽沟，在上颌托盘的远中端形成远中壁；
        </p>
        <p>
          3.使用轻体充填器在初印中注入轻体。确保充填器头始终在轻体印模材料内，避免产生气泡。充填轻体时要一次充填足够的量，在最后一颗磨牙的远中多充填一些，轻体材料要注满槽沟；
        </p>
        <p>
          4.托盘放入口内时，请助手协助牵拉对侧口角，托盘手柄与患者鼻子对齐，平直进入口腔后均匀慢压，使托盘允分就位。根据印模材料生产厂家推荐的固化时间定时。印模固化后取出托盘。
        </p>
      </div>
      <!--<img style="display: block;width: 100%" src="../../../common/imgs/help_center/make_model/make_model2.png" alt="">-->
    </div>
    <div class="help_center_line_height mt2rem">
      <p class="fz18rem">取得精确硅橡胶印模的技巧</p>
      <div class="fz16rem mt2rem">
        <p>1.使用隐力科技提供的定制塑料托盘；</p>
        <p>2.牙体必须清洁；</p>
        <p>3.请勿将硅橡胶印模材料与其它印模材料混合使用；</p>
        <p>
          4.材料的温度会影响工作时间及结固时间。可将印模材料适当冷冻，这样可以延长工作时间和结固时间；
        </p>
        <p>5.在厂家规定时间内进行操作。</p>
      </div>
    </div>
    <p class="silica_model fz22rem">数字模型标准</p>
    <p class="mt2rem mb2rem fz18rem">iTero口扫</p>
    <div class="fz16rem help_center_line_height">
      <p>
        1.登录iTero口扫资料网<a
          target="_blank"
          style="color: #333; text-decoration: underline"
          href="https://myitero.cn/"
          >https://myitero.cn/</a
        >
      </p>
      <p>2.选择诊所和医生</p>
      <img
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/model1.png"
        alt=""
      />
      <p>3.点击“患者”，并搜索需要导出的订单信息</p>
      <img
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/model2.png"
        alt=""
      />
      <p>4.选择订单，点击“导出”</p>
      <img
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/model3.png"
        alt=""
      />
      <p>5.导出设置如下：</p>
      <img
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/model4.png"
        alt=""
      />
      <p>6.导出完成后，在浏览器的“下载内容”中找到文件</p>
      <img
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/model5.png"
        alt=""
      />
      <p>7.找到后缀为.stl的文件即为上下颌3D数字文件</p>
      <img
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/model6.png"
        alt=""
      />
    </div>
    <p class="mt2rem mb2rem fz18rem">3Shape口扫</p>
    <div class="fz16rem help_center_line_height">
      <p>1.打开电脑上的3Shape TRIOS</p>
      <p>2.选择主菜单界面的案例</p>
      <img
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/3Shape1.png"
        alt=""
      />
      <p>3.在案例里搜索到需要导出模型的患者</p>
      <img
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/3Shape2.png"
        alt=""
      />
      <p>4.把患者的模型文件已STL格式导出</p>
      <img
        style="display: block; width: 70%"
        src="../../../common/imgs/help_center/make_model/3Shape3.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},

  methods: {},

  beforeDestroy() {},
  created() {},
};
</script>

<style lang="scss" scoped>
.silica_model {
}
</style>