<template>
  <div>
    <p class="fz28rem mb2rem ">初戴的注意事项</p>
    <p class="fz22rem mb1rem mt6rem ">矫治器佩戴要求</p>
    <div class="fz16rem help_center_line_height">
      <p>1.要求患者每天除吃饭、刷牙外，均要佩戴矫治器，每天佩戴时间不少于22小时；</p>
      <p>2.一定要将矫治器戴到无矫治力感且与牙面充分贴合后再更换新的矫治器；</p>
      <p>3.建议在睡觉前更换新矫治器；</p>
      <p>4.用餐、刷牙时取下矫治器。除喝水外，饮用其他液体饮料或酒时均应摘下矫治器，再次佩戴矫治器前用清水漱口；</p>
      <p>5.请患者保留佩戴过的最近5副矫治器，按编号保留在各自的包装盒内，以备不时之需；</p>
      <p>6.正常情况下，标准版矫治器按照矫治器编号依次佩戴，每副矫治器佩戴7-14天，然后更换下一副矫治器，直至矫治结束；</p>
      <p>7.必要时对矫治器稍做修整，可使戴用更舒适。</p>
    </div>
    <p class=" fz22rem mb1rem mt6rem ">进行必要的临床操作</p>
    <div class="fz16rem help_center_line_height">
      <p>1.附件的设计与制作，详见附件粘结；</p>
      <p>2.邻面去釉的临床操作，详见邻面去釉。</p>
    </div>
    <p class=" fz22rem mb1rem mt6rem ">患者佩戴矫治器指导</p>
    <div class="fz16rem help_center_line_height">
      <p>1.摘戴矫治器前请清洁双手，并确认矫治器编号是否正确。</p>
      <p>2.矫治器戴入办法:</p>
      <p>选择较难戴入的牙位先行就位；</p>
      <p>用双手将矫治器平放入口腔，矫治器与相应牙齿对齐后，慢慢从前牙戴入，用拇指(上颌)或食指(下颌)推压矫治器切端和𬌗面，直至矫治器完全就位；</p>
      <p>矫治器与牙面稍有不贴时，可让患者咬医用棉卷或咬胶，辅助矫治器就位。</p>
      <p>3.矫治器摘除方法：</p>
      <p>摘除上颌矫治器时，用拇指或食指扣住矫治器的后牙腭侧(内侧)边缘往下往外轻拉，两侧交替进行，慢慢从后往前，使矫治器逐步脱位；</p>
      <p>摘除下颌矫治器时，用拇指把矫治器后牙颊侧(外侧)边缘往上推起，两侧交替进行，慢慢从后往前.使矫治器逐步脱位；</p>
      <p>如仍较难脱位的，建议下领左侧用右手大拇指从后牙舌侧脱位，下领右侧用左手大拇指从舌侧脱位。</p>
      <p>4.经常使用清水清洗矫治器。</p>
      <p>如果因进食、刷牙、社交等需要将矫治器暂时摘除的情况，请将矫治器清洗后放入洁净的矫治器盒中保存；</p>
      <p>如有特殊情况需长时间摘除矫治器，则应将矫治器与矫治器盒清洗后晾干，并将矫治器装入矫治器盒后一同装入干燥的密封袋中保存。</p>
      <p>再次使用前需重新清洗，忌用消毒水、热水浸泡矫治器。</p>
      <p>5.戴矫治器后，口内如有异常反应，应尽快与医生联系处理，请勿让患者自行修整。</p>
      <p>6.矫治器损坏、变形或丢失时：让患者戴回上一步矫治器保持 ，并尽快与医生联系，及时采取补救措施。</p>
      <img class="mt2rem" style="display: block;width: 100%;" src="../../../common/imgs/matters/img.png" alt="">
    </div>
    <p class="fz22rem mb1rem mt6rem ">注意事项</p>
    <div class="fz16rem help_center_line_height">
      <p>1.矫治器摘除时，遇到牙齿上有附件的部位，应先用指甲掀起附件下方的矫治器边缘，使附件与矫治器分离，再慢慢摘下；</p>
      <p>2.如附件固位力较大摘戴困难，建议临床修整附件形态，可将附件边缘适当调磨圆钝，减小矫治器的固位力；</p>
      <p>3.摘戴矫治器时切勿使用蛮力。</p>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    computed: {

    },

    methods: {

    },

    beforeDestroy() {
    },
    created() {
    }
  }
</script>

<style scoped>

</style>