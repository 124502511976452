<template>
  <div>
    <p class="fz28rem ">患者依从性管理</p>
    <p class="fz16rem mb1rem mt6rem">无托槽隐形矫治器属于活动矫治器的一种，患者的依从性对矫治的成功与否起到至关重要的作用。</p>
    <p class="fz22rem mb1rem mt6rem ">患者依从性评估</p>
    <div class="fz16rem help_center_line_height">
      <p>1.初诊时的行为习惯</p>
      <p>如患者经常表现出嫌麻烦、爱吃零食、不能按时复诊等，容易出现配合不佳的情况。一般来说，成人患者的依从性好于未成年患者。</p>
      <p>对于成人患者，可以主要通过询问或问卷调查一些问题来评估其依从性。了解患者对于隐形矫治的期望和忍耐度。</p>
      <p>例如：能否坚持每天佩戴矫治器22小时以上？能否坚持除正餐外尽量不食用零食，不饮用饮料、咖啡？能否坚持每6-8周复诊一次？</p>
      <p>成年患者可以根据自己的实际情况做出实事求是的考量，从而和医生一起决定是否适合进行隐形矫治。</p>
      <p class="mt2rem">对于未成年患者，可通过与家长交流，了解患者在日常生活、学习中表现出来的其他方面的依从性；</p>
      <p>同时应让患者及其家长充分了解良好的依从性对保证隐形矫治取得成功的重要性；</p>
      <p>然后由医生、患者及家长一起，从依从性的角度来共同评估患者是否适合进行隐形矫治。</p>
      <p class="mt2rem">2.复诊时的习惯行为</p>
      <p>若患者经常延后复诊，则说明矫治器佩戴周期延长，每天佩戴矫治器时间不足。</p>
      <p>若患者复诊时经常没有佩戴矫治器，说明存在矫治器佩戴时间不足问题。</p>
      <p>通过复诊时与患者沟通、聊天，了解其工作生活、社交活动、作息习惯等，可以准确掌握患者戴用时间。</p>
      <p class="mt2rem">3.矫治器检查</p>
      <p>正常佩戴后的矫治器透明度下降，咬合面存在一定的磨耗。</p>
      <p>若患者的矫治器透明如新，无明显磨耗，大多属于佩戴时间不足。</p>
    </div>
    <p class="fz22rem mb1rem mt6rem ">患者依从性教育与监控</p>
    <div class="fz16rem help_center_line_height">
      <p>除了治疗前对患者的依从性进行评估，在整个隐形矫治的过程当中，尤其是刚开始1～3个月的适应期，应该对患者的依从性进行教育和监控。待适应期过后，如果患者依从性良好，那么一般来说已养成良好习惯，后续治疗可以顺利完成。</p>
      <p class="mt2rem">1.依从性教育</p>
      <p>在隐形矫治刚开始的阶段，临床医生可以利用复诊的时间或通过电话回访的形式向患者多次强调良好依从性对于保证治疗效果的重要性，以及如果依从性不好，可能会造成对患者的不良后果及经济损失。</p>
      <p>通过反复教育让患者自觉建立良好的依从性。</p>
      <p class="mt2rem">2.依从性监控</p>
      <p>除了教育以外，临床医生在整个矫治的全程都应仔细监控患者的依从性是否良好。了解患者在治疗过程中的依从性可以从以下几方面来进行：</p>
      <p>(1) 牙移动的进展</p>
      <p>虽然在隐形矫治过程中，患者实际牙移动与预期牙移动存在差距的原因很多，但是依从性不好，矫治器佩戴时间不够往往是最常见的原因。</p>
      <p>如果经过检查，矫治器就位情况、拟矫治牙移动间隙等都没有问题，而实际牙移动情况却不理想，那么往往提示患者依从性不好。</p>
      <p>(2) 取戴矫治器的难易程度</p>
      <p>如果患者依从性良好，佩戴矫治器时间足够，那么此时的矫治器应该已经释放完大部分应力，患者取戴矫治器应该比较容易。反之，如果患者在取戴矫治器的时候感觉仍然很困难，和初戴时差别不大，那么往往说明患者佩戴矫治器时间不够，依从性不好。</p>
      <p>对于依从性不好的患者，一方面应加强教育，另一方面可以暂缓发放新的矫治器，适当增加患者佩戴原有矫治器的时间，以完全达到预期效果。在矫治刚开始时，每次只给2步矫治器。根据依从性和矫治进展确定是否增加给予矫治器的数量。</p>
    </div>
    <p class="fz22rem mb2rem mt6rem ">矫治器佩戴的常见问题</p>
    <p style="margin-top: 0.3rem;" class="fz16rem ">针对患者佩戴矫治器后可能遇到的问题提供解决方法：</p>
    <div class="fz16rem help_center_line_height">
      <p class="mt2rem">1.戴矫治器影响发音</p>
      <p>初戴矫治器都会有些异物感，发音会有一定的影响，但大多患者在佩戴1～2周后均可以佩戴矫治器正常发音，不影响语言交流。</p>
      <p class="mt2rem">2.戴矫治器后口干或唾液分泌增多</p>
      <p>前突或前牙唇向错位明显的患者，在初戴矫治器时会感觉唇闭合受到一定影响，睡觉时口呼吸加重，这可能是导致口干的原因。</p>
      <p>这种情况下，首先检查佩戴矫治器后前牙区是否有开合存在。若存在前牙区开合，可适当调磨矫治器的后牙区高点，降低咬合高度，减小或消除前牙区开合，同时进行唇肌训练，改善唇闭合，适量多饮水。</p>
      <p>戴矫治器后有些患者唾液分泌增多，这是唾液腺对异物刺激的正常反应，1个月左右大多会恢复正常。</p>
      <p class="mt2rem">3.戴矫治器后牙齿疼痛</p>
      <p>一般佩戴新矫治器后，由于牙齿受力移动，大多会有酸胀、轻度疼痛感，一般会持续3～4天。</p>
      <p>这种疼痛是正常的正畸反应。正常情况下不会出现佩戴矫治器后牙齿疼痛明显甚至不能忍受的情况。</p>
    </div>
    <p class="fz22rem mb1rem mt6rem ">签署知情同意书</p>
    <p class="fz16rem help_center_line_height">治疗前与患者充分沟通，认真阅读、理解后签署《知情同意书》，以法律形式明确医患双方的责任和义务。</p>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    computed: {

    },

    methods: {

    },

    beforeDestroy() {
    },
    created() {
    }
  }
</script>

<style scoped>

</style>