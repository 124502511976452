import { render, staticRenderFns } from "./x_picture_shoot.vue?vue&type=template&id=6a24d6b6&scoped=true"
import script from "./x_picture_shoot.vue?vue&type=script&lang=js"
export * from "./x_picture_shoot.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a24d6b6",
  null
  
)

export default component.exports